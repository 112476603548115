export const gamesRemovedFromSearch = [
    'fuse3',
    'bubblez',
    'glassez',
    'patchgirlz',
    'clusterz',
    'boxiespacejump',
    'mysteriez',
    'fitz',
    'match10saga',
    'match10',
    'findcats',
    'tastymahjong',
    'funnymath',
    'sweetcaketower',
    'rocketracehighway',
    '2048colordesign',
    'beadz',
    'furtivedao',
    '2048',
    'fitz2',
    'wordz',
    'monsterstd',
    'patchworkzxmaz',
    'wellmahjong',
    'runrabbitrun',
    'howdareyou',
    'diggy',
    'keeperofthegrove',
    'missilecommand',
    'centipede',
    'pong',
    'breakout',
    'asteroids',
    'cookieconnectextra',
    'jewelacademy',
    'battleship',
    'mythicaljewels',
    'elementblocks',
    'bubblewoods',
    'totemia',
    'ginrummyplus',
    'chessclassic',
    '3ddarts',
    'goldmine',
    'omnomrun',
    'reversi',
    '8ballbilliardsclassic',
    'candybubble',
    'collapseblast',
    'jellyrock',
    'cuttherope2',
    'kingofthieves',
    'bouncydunk',
    'pondrace',
    'conveyordeli',
    'trapandkillvirus',
    'towertwist',
    'knifeflip',
    'smorewords',
    'guessit',
    'mahjongquest',
    'dealornodeal',
    'euchre',
    'canasta',
    'whist',
    'canastafortwo',
    'oklahomagin',
    'ginrummy',
    'coconutletterswap',
    'bankshot',
    'startswith',
    'bankshot9ball',
    'wahoomarbles',
    'chess',
];
